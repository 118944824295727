import { type ChangeEvent, type MouseEvent, useEffect, type JSX } from 'react'

import { adminGetUsers, adminUpdateUserAccess } from '../actions'
import { useAppDispatch, useAppSelector, useEmailInput } from '../hooks'
import { type AdminUser } from '../reducers/types'
import { classNames } from '../utils'

export function AdminUsersNewUserRow (): JSX.Element {
  const [emailInput, email] = useEmailInput('')
  const dispatch = useAppDispatch()
  const isDirty = email !== ''

  function onSave (e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    if (!isDirty) {
      return
    }

    dispatch(adminUpdateUserAccess(email, true))
  }

  const saveClasses = {
    'btn btn-success btn-new-user-save me-3': true,
    disabled: !isDirty
  }

  return (
    <tr>
      <td>
        {emailInput}
      </td>
      <td>&nbsp;</td>
      <td>
        <div className='form-check form-switch'>
          <input className='form-check-input' type='checkbox' checked readOnly />
        </div>
      </td>
      <td>
        <a href='/#' className={classNames(saveClasses)} onClick={onSave}>Lisää käyttäjä</a>
      </td>
    </tr>
  )
}

export function AdminUsersRow ({ user }: { user: AdminUser }): JSX.Element {
  const dispatch = useAppDispatch()

  function onChange (e: ChangeEvent<HTMLInputElement>) {
    dispatch(adminUpdateUserAccess(user.email, false))
  }

  return (
    <tr>
      <td>{user.email}</td>
      <td>{user.auth0Id}</td>
      <td>
        <div className='form-check form-switch'>
          <input className='form-check-input' type='checkbox' onChange={onChange} checked={user.useAuthorized} data-testid='admin-users-row-use-authorized-switch' />
        </div>
      </td>
      <td>&nbsp;</td>
    </tr>
  )
}

export default function AdminUsers (): JSX.Element {
  const users = useAppSelector<AdminUser[]>(state => state.admin.users)
  const pending = useAppSelector<boolean>(state => state.admin.usersPending)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(adminGetUsers())
  }, [dispatch])

  if (pending) {
    return (
      <div className='spinner-border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    )
  }

  return (
    <div className='form-outline pb-5'>
      <h1>Sallitut käyttäjät</h1>
      <table className='table'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Auth0Id</th>
            <th>Suunnittelijan käyttö sallittu</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {users.map(u => (
            <AdminUsersRow key={u.email} user={u} />
          ))}
        </tbody>
        <tfoot>
          <AdminUsersNewUserRow />
        </tfoot>
      </table>
    </div>
  )
}
