import { type JSX } from 'react'

import ReactTooltip from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'

interface TooltipProps {
  title: string
}
export default function Tooltip (props: TooltipProps): JSX.Element {
  const id: string = uuidv4()
  return (
    <div className='d-inline-block'>
      <i className='text-primary ms-2 fas fa-question-circle' data-tip data-for={id} />
      <ReactTooltip id={id} type='dark' effect='solid'>
        {props.title}
      </ReactTooltip>
    </div>
  )
}
