import { type JSX } from 'react'

import { useAppSelector } from '../hooks'
import { BUILDING_TYPE_BLOCK, type Plan } from '../reducers/types'

const buildingTypes: Record<string, number> = {
  '': 0,
  block: 0
  // 'terraced': 1,
  // 'duplex': 2,
  // 'detached': 3
}

const wirelessModeChoices: Record<string, number> = {
  hydrolinkWireless: 0,
  hydrolinkAir: 1
}

export default function Drawing (props: any): JSX.Element | null {
  const plan = useAppSelector<Plan>((state) => state.plan)

  if (plan.buildingType !== BUILDING_TYPE_BLOCK) {
    return null
  }
  if (plan.floorQuantity === undefined || plan.apartmentQuantity === undefined) {
    return null
  }

  const building = buildingTypes[plan.buildingType]
  const floors = plan.floorQuantity
  const apartments = plan.apartmentQuantity
  const cold = plan.waterMeterType === 'cold' || plan.waterMeterType === 'both'
  const hot = plan.waterMeterType === 'warm' || plan.waterMeterType === 'both'
  const screen = plan.apartmentScreensEnabled === 'yes'
  const wireless = plan.systemType === 'wireless'
  const wirelessMode = plan.wirelessMode ? wirelessModeChoices[plan.wirelessMode] : null
  const ambient = plan.temperatureMetersEnabled === 'yes'
  const meterPairsPerApartment = plan.moreThanOneMeterPerApartment === 'yes' ? 2 : 1
  const stairways = plan.stairwellQuantity

  const uri = `/Drawing/Svg?building=${building}&floors=${floors}&apartments=${apartments}&cold=${cold}&hot=${hot}&screen=${screen}&wireless=${wireless}${wirelessMode !== null ? '&wireless_mode=' + wirelessMode : ''}&ambient=${ambient}&meter_pairs_per_apartment=${meterPairsPerApartment}&stairways=${stairways}`

  return (
    <img alt='Preview schematic' src={uri} />
  )
}
