import { type JSX } from 'react'

import { Navigate } from 'react-router-dom'

import { useAppSelector } from '../hooks'

export function AuthenticatedLogin (): JSX.Element {
  const isAuthorized = useAppSelector<boolean>(state => state.user.claims?.isAuthorized ?? false)

  console.log('AuthenticatedLogin', { isAuthorized })

  if (isAuthorized) {
    return <Navigate to='/home' />
  }

  return (
    <div className='container'>
      <h2>Koka Suunnittelukone</h2>
      <p>Pääset aloittamaan suunnittelun heti, kun Koka on lisännyt sinut järjestelmään.</p>
      <a className='btn btn-primary' href='/Auth0/Login'>Kirjaudu uudestaan</a>
    </div>
  )
}

export default function Login (): JSX.Element {
  const isAuthenticated = useAppSelector<boolean>(state => state.user.isAuthenticated)

  console.log('Login', { isAuthenticated })

  if (isAuthenticated) {
    return <AuthenticatedLogin />
  }

  return (
    <div className='container'>
      <h2>Koka Suunnittelukone</h2>
      <p>
        Tervetuloa Kokan suunnittelukoneeseen. Tämä sivusto on tarkoitettu talotekniikka-alan suunnittelijoiden käyttöön. Aloita käyttö rekisteröitymällä tai kirjautumalla sisään alla olevasta painikkeesta.
        Jos olet käyttämässä suunnittelukonetta ensimmäistä kertaa niin Koka Oy hyväksyy ensin tilisi ja pääset heti sen jälkeen eteenpäin.
      </p>
      <a className='btn btn-primary' href='/Auth0/Login'>Kirjaudu sisään / Rekisteröidy</a>
    </div>
  )
}
