import { type JSX } from 'react'

import AdminProducts from './AdminProducts'
import AdminUsers from './AdminUsers'

export default function Admin (): JSX.Element {
  return (
    <div className='container'>
      <AdminProducts />
      <AdminUsers />
    </div>
  )
}
