import React from 'react'

import { createRoot } from 'react-dom/client'
import './index.css'
import './styles/nav.css'
import { Provider } from 'react-redux'

import { fetchClaims } from './actions'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'

store.dispatch(fetchClaims())

const container = document.getElementById('root')
if (container == null) {
  throw Error('Expecting element with id=root')
}

const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
