export interface Action<T> {
  type: string
  payload: T
}

export interface ActionWithoutPayload {
  type: string
}

export type ThunkActionWithoutReturnValue = (dispatch: any, getState: any) => Promise<void>

const actionTypes = {
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  CHOOSE_BUILDING_TYPE: 'CHOOSE_BUILDING_TYPE',
  UPDATE_PLAN: 'UPDATE_PLAN',
  CLAIMS_PENDING: 'CLAIMS_PENDING',
  CLAIMS_FULFILLED: 'CLAIMS_FULFILLED',
  CLAIMS_REJECTED: 'CLAIMS_REJECTED',
  PRODUCT_LIST_PENDING: 'PRODUCT_LIST_PENDING',
  PRODUCT_LIST_FULFILLED: 'PRODUCT_LIST_FULFILLED',
  PRODUCT_LIST_REJECTED: 'PRODUCT_LIST_REJECTED',
  ADMIN_GET_PRODUCTS_PENDING: 'ADMIN_GET_PRODUCTS_PENDING',
  ADMIN_GET_PRODUCTS_FULFILLED: 'ADMIN_GET_PRODUCTS_FULFILLED',
  ADMIN_GET_PRODUCTS_REJECTED: 'ADMIN_GET_PRODUCTS_REJECTED',
  ADMIN_GET_USERS_PENDING: 'ADMIN_GET_USERS_PENDING',
  ADMIN_GET_USERS_FULFILLED: 'ADMIN_GET_USERS_FULFILLED',
  ADMIN_GET_USERS_REJECTED: 'ADMIN_GET_USERS_REJECTED',
  ADMIN_UPDATE_USER_ACCESS_PENDING: 'ADMIN_UPDATE_USER_ACCESS_PENDING',
  ADMIN_UPDATE_USER_ACCESS_FULFILLED: 'ADMIN_UPDATE_USER_ACCESS_FULFILLED',
  ADMIN_UPDATE_USER_ACCESS_REJECTED: 'ADMIN_UPDATE_USER_ACCESS_REJECTED',
  ADMIN_UPDATE_PRODUCT_PENDING: 'ADMIN_UPDATE_PRODUCT_PENDING',
  ADMIN_UPDATE_PRODUCT_FULFILLED: 'ADMIN_UPDATE_PRODUCT_FULFILLED',
  ADMIN_UPDATE_PRODUCT_REJECTED: 'ADMIN_UPDATE_PRODUCT_REJECTED',
  REPORT_RESET: 'REPORT_RESET',
  REPORT_PENDING: 'REPORT_PENDING',
  REPORT_FULFILLED: 'REPORT_FULFILLED',
  REPORT_REJECTED: 'REPORT_REJECTED'
}

export default actionTypes
