import { combineReducers } from 'redux'

import { type AdminUser, type InitialState } from './types'
import actionTypes from '../actions/action_types'

const initialState: InitialState = {
  settings: {},
  plan: {
    companyName: '',
    buildingType: '',
    buildingQuantity: 1,
    stairwellQuantity: 1,
    floorQuantity: 1,
    apartmentQuantity: undefined,
    centralUnitPlacedInMiddle: '',
    waterMeterType: '',
    moreThanOneMeterPerApartment: '',
    meterSize: '',
    apartmentScreensEnabled: '',
    systemType: '',
    temperatureMetersEnabled: '',
    wirelessMode: ''
  },
  user: {
    isAuthenticated: false,
    claimsPending: false,
    claims: null
  },
  products: {
    pending: false,
    error: '',
    list: null
  },
  admin: {
    products: [],
    productsPending: false,
    productsError: '',
    users: [],
    usersPending: false,
    usersError: ''
  },
  report: {
    submittingToAddress: '',
    submittedToAddress: '',
    error: ''
  }
}

function settingsReducer (state = initialState.settings, action: any) {
  if (action.type === 'UPDATE_SETTINGS') {
    return action.payload
  }
  return state
}

function planReducer (state = initialState.plan, action: any) {
  if (action.type === actionTypes.CHOOSE_BUILDING_TYPE) {
    return { ...state, buildingType: action.payload }
  }
  if (action.type === actionTypes.UPDATE_PLAN) {
    return { ...state, ...action.payload }
  }
  return state
}

function userReducer (state = initialState.user, action: any) {
  if (action.type === actionTypes.CLAIMS_PENDING) {
    return {
      ...state,
      claimsPending: true
    }
  }

  if (action.type === actionTypes.CLAIMS_FULFILLED) {
    return {
      ...state,
      claimsPending: false,
      claims: action.payload,
      isAuthenticated: true
    }
  }

  if (action.type === actionTypes.CLAIMS_REJECTED) {
    return {
      ...state,
      claimsPending: false,
      claims: null,
      isAuthenticated: false
    }
  }

  return state
}

function productsReducer (state = initialState.products, action: any) {
  if (action.type === actionTypes.PRODUCT_LIST_PENDING) {
    return {
      ...state,
      pending: true
    }
  }

  if (action.type === actionTypes.PRODUCT_LIST_FULFILLED) {
    return {
      ...state,
      pending: false,
      list: action.payload,
      error: ''
    }
  }

  if (action.type === actionTypes.PRODUCT_LIST_REJECTED) {
    return {
      ...state,
      pending: false,
      list: null,
      error: action.payload.reason
    }
  }

  return state
}

/* eslint-disable sonarjs/cognitive-complexity */
function adminReducer (state = initialState.admin, action: any) {
  // Get users
  if (action.type === actionTypes.ADMIN_GET_USERS_PENDING) {
    return {
      ...state,
      usersPending: true
    }
  }
  if (action.type === actionTypes.ADMIN_GET_USERS_FULFILLED) {
    return {
      ...state,
      usersPending: false,
      users: action.payload,
      usersError: ''
    }
  }
  if (action.type === actionTypes.ADMIN_GET_USERS_REJECTED) {
    return {
      ...state,
      usersPending: false,
      users: [],
      usersError: action.payload
    }
  }

  // Update user access
  if (action.type === actionTypes.ADMIN_UPDATE_USER_ACCESS_PENDING) {
    return {
      ...state,
      usersPending: true
    }
  }
  if (action.type === actionTypes.ADMIN_UPDATE_USER_ACCESS_FULFILLED) {
    const isNew = state.users.find(u => u.email === action.payload.email) == null
    const isRemove = !action.payload.useAuthorized

    let users: AdminUser[]
    if (isNew) {
      users = state.users.concat([action.payload])
    } else if (isRemove) {
      users = state.users.filter(u => u.email !== action.payload.email)
    } else {
      users = state.users.map(u => u.email === action.payload.email ? action.payload : u)
    }

    return {
      ...state,
      usersPending: false,
      users,
      usersError: ''
    }
  }
  if (action.type === actionTypes.ADMIN_UPDATE_USER_ACCESS_REJECTED) {
    return {
      ...state,
      usersPending: false,
      usersError: action.payload
    }
  }

  // Get products
  if (action.type === actionTypes.ADMIN_GET_PRODUCTS_PENDING) {
    return {
      ...state,
      productsPending: true
    }
  }
  if (action.type === actionTypes.ADMIN_GET_PRODUCTS_FULFILLED) {
    return {
      ...state,
      productsPending: false,
      products: action.payload,
      productsError: ''
    }
  }
  if (action.type === actionTypes.ADMIN_GET_PRODUCTS_REJECTED) {
    return {
      ...state,
      productsPending: false,
      products: [],
      productsError: action.payload
    }
  }

  // Update product
  if (action.type === actionTypes.ADMIN_UPDATE_PRODUCT_PENDING) {
    return {
      ...state,
      productsPending: true
    }
  }
  if (action.type === actionTypes.ADMIN_UPDATE_PRODUCT_FULFILLED) {
    return {
      ...state,
      productsPending: false,
      products: state.products.map(p => p.id === action.payload.id ? { ...action.payload } : p),
      productsError: ''
    }
  }
  if (action.type === actionTypes.ADMIN_UPDATE_PRODUCT_REJECTED) {
    return {
      ...state,
      productsPending: false,
      productsError: action.payload
    }
  }

  return state
}

function reportReducer (state = initialState.report, action: any) {
  if (action.type === actionTypes.REPORT_RESET) {
    return initialState.report
  }

  if (action.type === actionTypes.REPORT_PENDING) {
    return {
      ...state,
      submittingToAddress: action.payload.email
    }
  }

  if (action.type === actionTypes.REPORT_FULFILLED) {
    return {
      ...state,
      submittingToAddress: '',
      submittedToAddress: action.payload.email,
      error: ''
    }
  }

  if (action.type === actionTypes.REPORT_REJECTED) {
    return {
      ...state,
      submittingToAddress: '',
      error: action.payload.reason
    }
  }

  return state
}

const rootReducer = combineReducers({
  settings: settingsReducer,
  plan: planReducer,
  user: userReducer,
  products: productsReducer,
  admin: adminReducer,
  report: reportReducer
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
