import { type ChangeEvent, useEffect, useState, type MouseEvent, useCallback, type JSX } from 'react'

import { adminGetProducts, adminUpdateProduct } from '../actions'
import { useAppDispatch, useAppSelector } from '../hooks'
import { type AdminProduct } from '../reducers/types'
import { classNames } from '../utils'

export function priceToInputValue (val: number | null): string {
  if (val === null) {
    return ''
  }

  return (val / 100).toFixed(2)
}

export function inputValueToPrice (val: string): number | null {
  if (val.trim() === '') {
    return null
  }

  return Math.floor(parseFloat(val) * 100)
}

export function useOptionalPriceInput (initialValue: number | null): [JSX.Element, number | null, () => void] {
  const [value, setValue] = useState(priceToInputValue(initialValue))
  const reset = useCallback(() => setValue(priceToInputValue(initialValue)), [initialValue, setValue])

  function onChange (e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)
  }

  const numberValue = inputValueToPrice(value)

  const classes = {
    'price-input': true,
    dirty: numberValue !== initialValue
  }

  return [
    <input
      key='price-input'
      type='number'
      step='0.01'
      value={value}
      onChange={onChange}
      className={classNames(classes)}
      data-testid='optional-price-input'
    />,
    numberValue,
    reset
  ]
}

export function AdminProductsRow ({ product }: { product: AdminProduct }): JSX.Element {
  const [priceCentsInput, priceCents, resetPriceCents] = useOptionalPriceInput(product.priceCents)
  const dispatch = useAppDispatch()
  const saveDisabled = priceCents === product.priceCents

  function onSave (e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    if (saveDisabled) {
      return
    }

    dispatch(adminUpdateProduct(product.id, priceCents))
  }

  function onCancel (e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    resetPriceCents()
  }

  const saveClasses = {
    'btn btn-success btn-product-save me-3': true,
    disabled: saveDisabled
  }

  const cancelClasses = {
    'btn btn-danger btn-product-cancel': true,
    disabled: saveDisabled
  }

  return (
    <tr>
      <td>{product.id}</td>
      <td>{product.description}</td>
      <td>{priceCentsInput}</td>
      <td>
        <a href='/#' className={classNames(saveClasses)} onClick={onSave} data-testid='admin-products-row-save'>Save</a>
        <a href='/#' className={classNames(cancelClasses)} onClick={onCancel} data-testid='admin-products-row-cancel'>Cancel</a>
      </td>
    </tr>
  )
}

export default function AdminProducts (): JSX.Element {
  const products = useAppSelector<AdminProduct[]>(state => state.admin.products)
  const pending = useAppSelector<boolean>(state => state.admin.productsPending)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(adminGetProducts())
  }, [dispatch])

  if (pending) {
    return (
      <div className='spinner-border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    )
  }

  return (
    <div className='form-outline pb-5'>
      <h1>Tuotteet</h1>
      <table className='table'>
        <thead>
          <tr>
            <th>Tunniste</th>
            <th>Kuvaus</th>
            <th>Hinta (&euro;)</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {products.map(p => (
            <AdminProductsRow key={p.id} product={p} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
