import { type JSX } from 'react'

import { Link } from 'react-router-dom'

import { useAppSelector } from '../hooks'

export default function AdminButton (): JSX.Element | null {
  const isAdmin = useAppSelector<boolean>(state =>
    state.user.isAuthenticated && (state.user.claims?.isAdmin ?? false))
  if (!isAdmin) {
    return null
  }

  return (
    <Link to='/admin' className='btn btn-primary'>Admin</Link>
  )
}
