export interface InitialState {
  settings: Record<any, any>
  plan: Plan
  user: User
  products: Products
  admin: Admin
  report: Report
}

export type BuildingType = 'block' | 'terraced' | 'duplex' | 'detached'
export const BUILDING_TYPE_TERRACED: BuildingType = 'terraced'
export const BUILDING_TYPE_BLOCK: BuildingType = 'block'
export const BUILDING_TYPE_DUPLEX: BuildingType = 'duplex'
export const BUILDING_TYPE_DETACHED: BuildingType = 'detached'

export type YesNo = 'yes' | 'no'
export type WaterMeterType = 'cold' | 'warm' | 'both'
export type MeterSize = 'dn15' | 'dn20'
export type SystemType = 'wired' | 'wireless'
export type WirelessMode = 'hydrolinkWireless' | 'hydrolinkAir'

export interface Plan {
  companyName: string
  buildingType: '' | BuildingType
  buildingQuantity?: number
  stairwellQuantity?: number
  floorQuantity?: number
  apartmentQuantity?: number
  centralUnitPlacedInMiddle: '' | YesNo
  waterMeterType: '' | WaterMeterType
  moreThanOneMeterPerApartment: '' | YesNo
  meterSize: '' | MeterSize
  apartmentScreensEnabled: '' | YesNo
  systemType: '' | SystemType
  temperatureMetersEnabled: '' | YesNo
  wirelessMode: '' | WirelessMode
}

export interface Claims {
  name: string
  email: string
  message: string
  nickname: string
  picture: string
  updatedAt: string
  isAuthorized: boolean
  isAdmin?: boolean
}

export interface User {
  isAuthenticated: boolean
  claims: Claims | null
  claimsPending: boolean
}

export interface ProductsListEntry {
  description: string
  quantity: number
}

export interface ProductsList {
  entries: ProductsListEntry[]
  totalCost: number
}

export interface Products {
  pending: boolean
  error: string
  list: ProductsList | null
}

export interface AdminProduct {
  id: string
  description: string
  priceCents: number | null
}

export interface AdminUser {
  email: string
  auth0Id: string
  useAuthorized: boolean
}

export interface Admin {
  products: AdminProduct[]
  productsPending: boolean
  productsError: string
  users: AdminUser[]
  usersPending: boolean
  usersError: string
}

export interface Report {
  submittingToAddress: string
  submittedToAddress: string
  error: string
}

export const descriptions: Record<string, Record<string, any>> = {
  companyName: { description: 'Taloyhtiön nimi' },
  buildingType: {
    description: 'Rakennuksen tyyppi',
    block: { description: 'Kerrostalo' },
    terraced: { description: 'Rivitalo' },
    duplex: { description: 'Paritalo' },
    detached: { description: 'Erillistalo' }
  },
  buildingQuantity: { description: 'Rakennusten lukumäärä' },
  stairwellQuantity: { description: 'Rappukäytävien lukumäärä' },
  floorQuantity: { description: 'Kerrosten lukumäärä' },
  apartmentQuantity: { description: 'Asuntojen lukumäärä' },
  centralUnitPlacedInMiddle: { description: 'Keskusyksikön voi sijoittaa rakennusten väliin', yes: { description: 'Kyllä' }, no: { description: 'Ei' } },
  waterMeterType: { description: 'Kohteessa mitataan', cold: { description: 'Kylmä vesi' }, warm: { description: 'Lämmin vesi' }, both: { description: 'Sekä kylmä että lämmin vesi' } },
  moreThanOneMeterPerApartment: { description: 'Enemmän kuin 1 mittarointia / huoneisto', yes: { description: 'Kyllä' }, no: { description: 'Ei' } },
  meterSize: { description: 'Mittarikoko', dn15: { description: 'DN 15' }, dn20: { description: 'DN 20' } },
  apartmentScreensEnabled: { description: 'Huoneistonäytöillä', yes: { description: 'Kyllä' }, no: { description: 'Ei' } },
  systemType: { description: 'Langallinen / langaton järjestelmä', wired: { description: 'Langallinen' }, wireless: { description: 'Langaton' } },
  temperatureMetersEnabled: { description: 'Lämpötilakosteusantureilla', yes: { description: 'Kyllä' }, no: { description: 'Ei' } },
  wirelessMode: { description: 'Langaton tekniikka', hydrolinkWireless: { description: 'Hydrolink Wireless' }, hydrolinkAir: { description: 'Hydrolink Air' } }
}
