import { type JSX } from 'react'

import { useAppSelector } from '../hooks'

export default function LogoutButton (): JSX.Element | null {
  const isAuthenticated = useAppSelector<boolean>(state => state.user.isAuthenticated)
  if (!isAuthenticated) {
    return null
  }

  return (
    <a className='btn btn-light' href='/Auth0/Logout'>Kirjaudu ulos</a>
  )
}
