import { type JSX } from 'react'

import Tooltip from './Tooltip'
import { classNames } from '../../utils'

interface RadioButtonsOption {
  value: any
  display: string
  disabled?: boolean
  title?: string
  disabledReasonTooltip?: string
  tooltip?: string
}

interface RadioButtonsProps {
  name: string
  value: any
  options: RadioButtonsOption[]
  onChange: (value: any) => void
  disabled?: boolean
}

export default function RadioButtons (props: RadioButtonsProps): JSX.Element {
  const set = (value: any) => {
    if (props.disabled) {
      return
    }

    props.onChange(value)
  }

  const isDisabled = !!props.disabled
  const classes = {
    'form-check form-check-inline': true,
    disabled: isDisabled
  }

  const radioButtons = []
  for (const opt of props.options) {
    const showDisabledReasonToolTip = opt.disabled && opt.disabledReasonTooltip
    const showTooltip = !!opt.tooltip

    radioButtons.push(
      <div className={classNames(classes)} key={opt.value}>
        <input
          data-testid={`${props.name}-${opt.value}`}
          className='form-check-input'
          disabled={isDisabled || opt.disabled}
          type='radio'
          name={props.name}
          id={`${props.name}_${opt.value}`}
          value={opt.value}
          checked={props.value === opt.value}
          onChange={(e) => { set(e.target.value) }}
          title={opt.title}
        />
        <label className='form-check-label' htmlFor={`${props.name}_${opt.value}`} title={opt.title}>{opt.display}</label>
        {showDisabledReasonToolTip ? <Tooltip title={opt.disabledReasonTooltip ?? ''} /> : null}
        {showTooltip ? <Tooltip title={opt.tooltip ?? ''} /> : null}
      </div>
    )
  }

  return (
    <div className='mb-3'>
      {radioButtons}
    </div>
  )
};
