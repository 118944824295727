import { Link } from 'react-router-dom'

const Intro = () => {
  const hlAirHeaderStyle = {
    listStyleType: 'none',
    paddingLeft: 0,
    marginBottom: 0
  }

  const hlAirHeaderLiStyle = {
    display: 'inline-flex',
    marginRight: '16px'
  }

  return (
    <div className='container pb-5'>
      <h2>Suunnittelukone</h2>
      <p>
        Tervetuloa käyttämään Kokan suunnittelukonetta. Tämän sivuston tarkoitus on helpottaa suunnittelijoiden työtä vedenmittausjärjestelmän osalta.
        Suunnittelukone muodostaa esitäytetyn kaavion järjestelmästä antamiesi tietojen perusteella. Samalla saat myös hinta-arvion vedenmittausjärjestelmästä.
      </p>
      <p className='pb-5'>
        Huomioithan, että hinta-arvio sisältää urakoisijan arvioidun tarvikekatteen, mutta ei työkustannuksia.
        Koka Oy varaa oikeuden muuttaa hintoja hinta-arviosta huolimatta.
        Jos haluat tarkan tarjouksen, laita viimeisellä sivulla rasti yhteydenottoruutuun.
        Näin Koka Oy:n myynti saa automaattisesti tiedon suunnitelmastasi ja ottaa sinuun yhteyttä.
      </p>
      <div className='row'>
        <div className='col-12 col-md-4'>
          <h4>HYDROLINK Wireless</h4>
          <p>
            <strong>
              Langattomalla M-Bus-tekniikalla toteutettu älykäs vedenmittausjärjestelmä huoneistokohtaiseen vedenmittaukseen ja tiedonsiirtoon.
            </strong>
          </p>
          <p>
            HYDROLINK Wireless -järjestelmä koostuu HYDRODIGIT-TX -vesimittareista ja keskusyksiköstä sekä tarvittaessa vahvistimista.
          </p>
          <ul>
            <li>Hydrolink Online-palvelu</li>
            <li>Automaattiset raportit isännöitsijälle</li>
            <li>Kulutusraportit laskutusohjelmiin</li>
            <li>Mobiilisovellus asukkaille</li>
            <li>Lukema tarkastettavissa suoraan mittarista</li>
            <li>Vuotovalvonta ja -hälytys</li>
            <li>Huoneistonäytöt lisävarusteena</li>
            <li>Lämpötila- ja kosteusanturit lisävarusteena</li>
            <li>API-rajapinta</li>
            <li>Modbus-liitäntä rakennusautomaatioon</li>
          </ul>
        </div>
        <div className='col-12 col-md-4'>
          <h4>HYDROLINK Line</h4>
          <p>
            <strong>
              M-Bus-väylätekniikalla toteutettu älykäs vedenmittausjärjestelmä huoneistokohtaiseen vedenmittaukseen ja tiedonsiirtoon.
            </strong>
          </p>
          <p>
            HYDROLINK Line -järjestelmä koostuu GSD-RFM-vesimittareista, keskusyksiköstä ja tiedonsiirtoyksiköstä.
          </p>
          <ul>
            <li>Hydrolink Online-palvelu</li>
            <li>Automaattiset raportit isännöitsijälle</li>
            <li>Kulutusraportit laskutusohjelmiin</li>
            <li>Mobiilisovellus asukkaille</li>
            <li>Lukema tarkastettavissa suoraan mittarista</li>
            <li>Vuotovalvonta ja -hälytys</li>
            <li>
              Huoneistonäytöt lisävarusteena
              <ul>
                <li>Vedenkulutus, lämpötila, ilmankosteus</li>
              </ul>
            </li>
            <li>API-rajapinta</li>
            <li>Modbus-liitäntä rakennusautomaatioon</li>
          </ul>
        </div>
        <div className='col-12 col-md-4 hl-air-header'>
          <ul style={hlAirHeaderStyle}>
            <li style={hlAirHeaderLiStyle}><h4>HYDROLINK Air</h4></li>
            <li style={hlAirHeaderLiStyle}><a href='files/Hydrolink-Air.zip'>Lataa suunnitteluohjeet</a></li>
          </ul>
          <p>
            <strong>
              Langattomalla LoraWan-tekniikalla toteutettu älykäs vedenmittausjärjestelmä huoneistokohtaiseen vedenmittaukseen.
            </strong>
          </p>
          <p>
            HYDROLINK Air -järjestelmä koostuu HYDRODIGIT-DT -vesimittareista ja LoraWan-tukiasemasta. Vahvistimia ei tarvita.
          </p>
          <ul>
            <li>Hydrolink Online-palvelu</li>
            <li>Automaattiset raportit isännöitsijälle</li>
            <li>Kulutusraportit laskutusohjelmiin</li>
            <li>Mobiilisovellus asukkaille</li>
            <li>Lukema tarkastettavissa suoraan mittarista</li>
            <li>Vuotovalvonta ja -hälytys</li>
            <li>Huoneistonäytöt lisävarusteena</li>
            <li>Lämpötila- ja kosteusanturit lisävarusteena</li>
            <li>API-rajapinta</li>
          </ul>
        </div>
      </div>
      <Link className='btn btn-primary' to='/wizard'>Aloita suunnittelu</Link>
    </div>
  )
}

export default Intro
