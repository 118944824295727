import actionTypes, { type Action, type ActionWithoutPayload, type ThunkActionWithoutReturnValue } from './action_types'
import { type Plan } from '../reducers/types'

const CREDS_SAME_ORG = 'same-origin'
const MEDIA_TYPE_JSON = 'application/json'

async function parseJsonResponse (response: Response): Promise<any> {
  if (response.ok) {
    return await response.json()
  } else {
    throw await response.json()
  }
}

export const chooseBuildingType = (buildingType: string): Action<string> => {
  return { type: actionTypes.CHOOSE_BUILDING_TYPE, payload: buildingType }
}

export const updatePlan = (planProperties: Partial<Plan>): Action<Partial<Plan>> => {
  return { type: actionTypes.UPDATE_PLAN, payload: planProperties }
}

export function fetchClaims (): ThunkActionWithoutReturnValue {
  return async (dispatch: any): Promise<void> => {
    dispatch({ type: actionTypes.CLAIMS_PENDING })

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Accept: MEDIA_TYPE_JSON
      },
      credentials: CREDS_SAME_ORG
    }

    await fetch('/Auth0/Claims', options)
      .then(async response => await response.json())
      .then(data => {
        dispatch({ type: actionTypes.CLAIMS_FULFILLED, payload: data })
      })
      .catch(reason => {
        dispatch({ type: actionTypes.CLAIMS_REJECTED, payload: { reason } })
      })
  }
}

export function generateProductList (): ThunkActionWithoutReturnValue {
  return async (dispatch: any, getState: any): Promise<void> => {
    dispatch({ type: actionTypes.PRODUCT_LIST_PENDING })

    const plan = getState().plan
    const options: RequestInit = {
      method: 'POST',
      headers: {
        Accept: MEDIA_TYPE_JSON,
        'Content-Type': MEDIA_TYPE_JSON
      },
      credentials: CREDS_SAME_ORG,
      body: JSON.stringify(plan)
    }

    await fetch('/Products/GenerateProductList', options)
      .then(parseJsonResponse)
      .then(data => {
        dispatch({ type: actionTypes.PRODUCT_LIST_FULFILLED, payload: data })
      })
      .catch(reason => {
        dispatch({ type: actionTypes.PRODUCT_LIST_REJECTED, payload: { reason } })
      })
  }
}

export function adminGetUsers (): ThunkActionWithoutReturnValue {
  return async (dispatch: any): Promise<void> => {
    dispatch({ type: actionTypes.ADMIN_GET_USERS_PENDING })

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Accept: MEDIA_TYPE_JSON
      },
      credentials: CREDS_SAME_ORG
    }

    await fetch('/Admin/AuthorizedUsers', options)
      .then(async response => await response.json())
      .then(data => {
        dispatch({ type: actionTypes.ADMIN_GET_USERS_FULFILLED, payload: data })
      })
      .catch(reason => {
        dispatch({ type: actionTypes.ADMIN_GET_USERS_REJECTED, payload: { reason } })
      })
  }
}

export function adminGetProducts (): ThunkActionWithoutReturnValue {
  return async (dispatch: any): Promise<void> => {
    dispatch({ type: actionTypes.ADMIN_GET_PRODUCTS_PENDING })

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Accept: MEDIA_TYPE_JSON
      },
      credentials: CREDS_SAME_ORG
    }

    await fetch('/Admin/Products', options)
      .then(async response => await response.json())
      .then(data => {
        dispatch({ type: actionTypes.ADMIN_GET_PRODUCTS_FULFILLED, payload: data })
      })
      .catch(reason => {
        dispatch({ type: actionTypes.ADMIN_GET_PRODUCTS_REJECTED, payload: { reason } })
      })
  }
}

export function adminUpdateUserAccess (email: string, useAuthorized: boolean): ThunkActionWithoutReturnValue {
  const payload = {
    email,
    useAuthorized
  }

  return async (dispatch: any, getState: any): Promise<void> => {
    dispatch({ type: actionTypes.ADMIN_UPDATE_USER_ACCESS_PENDING })

    const options: RequestInit = {
      method: 'PUT',
      headers: {
        Accept: MEDIA_TYPE_JSON,
        'Content-Type': MEDIA_TYPE_JSON
      },
      credentials: CREDS_SAME_ORG,
      body: JSON.stringify(payload)
    }

    await fetch('/Admin/UpdateUserAccess', options)
      .then(parseJsonResponse)
      .then(data => {
        dispatch({ type: actionTypes.ADMIN_UPDATE_USER_ACCESS_FULFILLED, payload: data })
      })
      .catch(reason => {
        dispatch({ type: actionTypes.ADMIN_UPDATE_USER_ACCESS_REJECTED, payload: { reason } })
      })
  }
}

export function adminUpdateProduct (id: string, priceCents: number | null): ThunkActionWithoutReturnValue {
  const payload = {
    id,
    priceCents
  }

  return async (dispatch: any, getState: any): Promise<void> => {
    dispatch({ type: actionTypes.ADMIN_UPDATE_PRODUCT_PENDING })

    const options: RequestInit = {
      method: 'PUT',
      headers: {
        Accept: MEDIA_TYPE_JSON,
        'Content-Type': MEDIA_TYPE_JSON
      },
      credentials: CREDS_SAME_ORG,
      body: JSON.stringify(payload)
    }

    await fetch('/Admin/UpdateProduct', options)
      .then(parseJsonResponse)
      .then(data => {
        dispatch({ type: actionTypes.ADMIN_UPDATE_PRODUCT_FULFILLED, payload: data })
      })
      .catch(reason => {
        dispatch({ type: actionTypes.ADMIN_UPDATE_PRODUCT_REJECTED, payload: { reason } })
      })
  }
}

export interface CreateReportPayload { contact: boolean, email: string, plan: Plan, message: string }
export function createReport ({ contact, email, plan, message }: CreateReportPayload): ThunkActionWithoutReturnValue {
  return async (dispatch: any) => {
    dispatch({ type: actionTypes.REPORT_PENDING, payload: { email, message } })

    const options: RequestInit = {
      method: 'POST',
      headers: {
        Accept: MEDIA_TYPE_JSON,
        'Content-Type': MEDIA_TYPE_JSON
      },
      credentials: CREDS_SAME_ORG,
      body: JSON.stringify(plan)
    }

    const url = `/Report/SendReport?email=${encodeURIComponent(email)}&contact=${contact.toString()}&msg=${encodeURIComponent(message)}`

    await fetch(url, options)
      .then(async response => {
        if (!response.ok) {
          throw await response.json()
        }

        dispatch({ type: actionTypes.REPORT_FULFILLED, payload: { email, message } })
      })
      .catch(reason => {
        dispatch({ type: actionTypes.REPORT_REJECTED, payload: { reason } })
      })
  }
}

export function resetReportState (): ActionWithoutPayload {
  return { type: actionTypes.REPORT_RESET }
}
