import { type ChangeEvent, useState, type JSX } from 'react'

import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useEmailInput (initialValue: string, className?: string, placeholder?: string): [JSX.Element, string] {
  const [value, setValue] = useState(initialValue)

  function onChange (e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)
  }

  return [
    <input
      key='email-input'
      type='email'
      value={value}
      onChange={onChange}
      data-testid='email-input'
      className={className}
      placeholder={placeholder}
    />,
    value
  ]
}

export function useMessageInput (initialValue: string, className?: string, placeholder?: string): [JSX.Element, string] {
  const [value, setValue] = useState(initialValue)

  function onChange (e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)
  }

  return [
    <input
      key='message-input'
      type='text'
      value={value}
      onChange={onChange}
      data-testid='more-info-input'
      className={className}
      placeholder={placeholder}
    />,
    value
  ]
}

export function useCheckInput (initialValue: boolean, options?:
{ id?: string, className?: string, name?: string, testId?: string }): [JSX.Element, boolean] {
  const [value, setValue] = useState(initialValue)

  function onChange (e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.checked)
  }

  return [
    <input
      key={`check-input-${options?.id}`}
      id={options?.id}
      name={options?.name}
      className={options?.className}
      type='checkbox'
      checked={value}
      onChange={onChange}
      data-testid={options?.testId}
    />,
    value
  ]
}

export function useMdbCheckInput (initialValue: boolean, label: string, options?: { id?: string, testId?: string }): [JSX.Element, boolean] {
  const inputId = options?.id ?? 'flexCheckDefault'
  const [checkInput, check] = useCheckInput(initialValue, { id: inputId, className: 'form-check-input', testId: options?.testId })

  return [
    <div className='form-check' key={`mdb-check-input-${options?.id}`}>
      {checkInput}
      <label className='form-check-label' htmlFor={inputId}>{label}</label>
    </div>,
    check
  ]
}
