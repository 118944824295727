import './App.css'
import { type ReactNode, type JSX, type ReactElement } from 'react'

import { Provider } from '@rollbar/react'
import {
  HashRouter as Router,
  Link, Navigate,
  Route, Routes
} from 'react-router-dom'

import Admin from './components/Admin'
import AdminButton from './components/AdminButton'
import Intro from './components/Intro'
import Login from './components/Login'
import LogoutButton from './components/LogoutButton'
import Wizard from './components/Wizard'
import { RollbarSettings } from './constants/rollbar.env'
import { useAppSelector } from './hooks'

const rollbarConfig = {
  accessToken: RollbarSettings.AccessToken,
  environment: RollbarSettings.Environment
}

export function LoadingSpinner (): JSX.Element {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
          <div className='loading-spinner' data-testid='loading-spinner' />
        </div>
      </div>
    </div>
  )
}

export function UnauthorizedRedirect ({ to, children }: { to: string, children: ReactNode }): ReactElement {
  const claimsPending = useAppSelector<boolean>(state => state.user.claimsPending)
  const isAuthorized = useAppSelector<boolean>(state =>
    state.user.isAuthenticated && (state.user.claims?.isAuthorized ?? false))

  if (claimsPending) {
    return <LoadingSpinner />
  }

  if (!isAuthorized) {
    return <Navigate to={to} />
  }

  return <>{children}</>
}

export function NonAdminRedirect ({ to, children }: { to: string, children: ReactNode }): ReactElement {
  const claimsPending = useAppSelector<boolean>(state => state.user.claimsPending)
  const isAdmin = useAppSelector<boolean>(state =>
    state.user.isAuthenticated && (state.user.claims?.isAdmin ?? false))

  if (claimsPending) {
    return <LoadingSpinner />
  }

  if (!isAdmin) {
    return <Navigate to={to} />
  }

  return <>{children}</>
}

function App (): ReactElement {
  return (
    <Provider config={rollbarConfig}>
      <Router>
        <div style={{ position: 'relative' }}>
          <img alt='' src='images/header-bg_2.svg' className='HEADER_BGR center' style={{ width: '100%' }} />
          <Link to='/'>
            <img src='images/hydrolink_w.svg' style={{ position: 'absolute', top: '20px', left: '50%', width: '14%', marginLeft: '-7%' }} alt='' />
          </Link>
          <div className='header-actions'>
            <AdminButton />
            <LogoutButton />
          </div>
        </div>
        <div className='container mb-4'>
          <Routes>
            <Route
              index element={
                <Login />
            }
            />
            <Route
              path='/home' element={
                <UnauthorizedRedirect to='/'>
                  <Intro />
                </UnauthorizedRedirect>
            }
            />
            <Route
              path='/home' element={
                <UnauthorizedRedirect to='/'>
                  <Intro />
                </UnauthorizedRedirect>
            }
            />
            <Route
              path='/wizard' element={
                <UnauthorizedRedirect to='/'>
                  <Wizard />
                </UnauthorizedRedirect>
            }
            />
            <Route
              path='/admin' element={
                <NonAdminRedirect to='/'>
                  <Admin />
                </NonAdminRedirect>
            }
            />
          </Routes>
        </div>
      </Router>
    </Provider>
  )
}

export default App
