import { useState, useRef, useCallback, type JSX } from 'react'

import { Link } from 'react-router-dom'
import StepWizard from 'react-step-wizard'
import { type Dispatch } from 'redux'

import RadioButtons from './common/RadioButtons'
import Tooltip from './common/Tooltip'
import Drawing from './Drawing'
import Nav from './Nav'
import { chooseBuildingType, createReport, generateProductList, resetReportState, updatePlan } from '../actions'
import { useAppDispatch, useAppSelector, useEmailInput, useMdbCheckInput, useMessageInput } from '../hooks'
import {
  type ProductsListEntry,
  descriptions,
  type Plan,
  type YesNo,
  type MeterSize,
  BUILDING_TYPE_BLOCK, BUILDING_TYPE_TERRACED, BUILDING_TYPE_DUPLEX, BUILDING_TYPE_DETACHED, type ProductsList
} from '../reducers/types'
import { classNames } from '../utils'

const set = (key: any, value: any, type: string) => {
  if (type === 'number') {
    if (typeof value === 'string') {
      value = isNaN(parseInt(value)) ? undefined : parseInt(value)
    } else {
      value = undefined
    }
  }
  key(value)
}

const Wizard = () => {
  const topRef = useRef<HTMLDivElement>(null)

  const onStepChange = () => {
    /* istanbul ignore next */
    if (window.scrollY > (topRef?.current?.offsetTop ?? 0)) {
      topRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className='container'>
      <div className='jumbotron'>
        <div className='row' ref={topRef}>
          <div className='col-12 mb-4'>
            <StepWizard
              onStepChange={onStepChange}
              nav={<Nav />}
            >
              <BuildingTypeStep />
              <BuildingDetails />
              <MeterDetails />
              <Summary />
            </StepWizard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wizard

const BuildingTypeStep = (props: any) => {
  const dispatch = useAppDispatch()
  const plan = useAppSelector<Plan>((state) => state.plan)

  const primaryButtonClass = 'btn-primary'
  const whiteButtonClass = 'btn-white'

  const chooseBuilding = async (buildingType: string) => {
    dispatch(chooseBuildingType(buildingType))
    props.nextStep()
  }

  return (
    <div>
      <h4 className='mb-4'>Taloyhtiön tyyppi</h4>
      <div className='row mb-4'>
        <div className='col-6'>
          <button
            data-testid='building-block'
            className={`btn btn-block left p-4  ${plan.buildingType === BUILDING_TYPE_BLOCK
                            ? primaryButtonClass
                            : whiteButtonClass}`}
            onClick={() => { chooseBuilding(BUILDING_TYPE_BLOCK) }}
            style={{ textAlign: 'left' }}
          >
            <i className='fas fa-building fa-5x mr-4' style={{ color: '#d2ddec', float: 'right' }} />
            <span className='ml-2'>Kerrostalo</span>
          </button>
        </div>
        <div className='col-6'>
          <button
            data-testid='building-terraced'
            className={`btn btn-block left p-4  ${plan.buildingType === BUILDING_TYPE_TERRACED
                            ? primaryButtonClass
: whiteButtonClass}`}
            onClick={() => { chooseBuilding(BUILDING_TYPE_TERRACED) }}
            style={{ textAlign: 'left' }}
          >
            <i className='fas fa-city fa-5x mr-4' style={{ color: '#d2ddec', float: 'right' }} />
            <span className='ml-2'>Rivitalo</span>
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <button
            data-testid='building-duplex'
            className={`btn btn-block left p-4  ${plan.buildingType === BUILDING_TYPE_DUPLEX
                            ? primaryButtonClass
: whiteButtonClass}`}
            onClick={() => { chooseBuilding(BUILDING_TYPE_DUPLEX) }}
            style={{ textAlign: 'left' }}
          >
            <i className='fas fa-home fa-5x mr-4' style={{ color: '#d2ddec', float: 'right' }} />
            <span className='ml-2'>Paritalo</span>
          </button>
        </div>
        <div className='col-6'>
          <button
            data-testid='building-detached'
            className={`btn btn-block left p-4  ${plan.buildingType === BUILDING_TYPE_DETACHED
                            ? primaryButtonClass
: whiteButtonClass}`}
            onClick={() => { chooseBuilding(BUILDING_TYPE_DETACHED) }}
            style={{ textAlign: 'left' }}
          >
            <i className='fas fa-home fa-5x mr-4' style={{ color: '#d2ddec', float: 'right' }} />
            <span className='ml-2'>Erillistalo</span>
          </button>
        </div>
      </div>
      <Link to='/home' className='btn btn-default mt-4'>Palaa alkuun</Link>
    </div>
  )
}

const BuildingDetails = (props: any) => {
  const plan = useAppSelector<Plan>((state) => state.plan)

  return (
    <div>
      {plan.buildingType === BUILDING_TYPE_BLOCK ? <BuildingDetailsBlock props={props} /> : null}
      {plan.buildingType === BUILDING_TYPE_TERRACED ? <BuildingDetailsTerraced props={props} /> : null}
      {plan.buildingType === BUILDING_TYPE_DUPLEX ? <BuildingDetailsTerraced props={props} /> : null}
      {plan.buildingType === BUILDING_TYPE_DETACHED ? <BuildingDetailsTerraced props={props} /> : null}
    </div>
  )
}

const BuildingDetailsBlock = (props: any) => {
  const plan = useAppSelector<Plan>((state) => state.plan)
  const dispatch = useAppDispatch()
  const [companyName, setCompanyName] = useState<string>(plan.companyName)
  const [buildingQuantity, setBuildingQuantity] = useState<number | undefined>(plan.buildingQuantity)
  const [stairwellQuantity, setStairwellQuantity] = useState<number | undefined>(plan.stairwellQuantity)
  const [floorQuantity, setFloorQuantity] = useState<number | undefined>(plan.floorQuantity)
  const [apartmentQuantity, setApartmentQuantity] = useState<number | undefined>(plan.apartmentQuantity)

  const isValid = companyName !== '' &&
        (buildingQuantity ?? 0) !== 0 &&
        (stairwellQuantity ?? 0) !== 0 &&
        (floorQuantity ?? 0) !== 0 &&
        (apartmentQuantity ?? 0) !== 0

  const proceed = async () => {
    const updatedPlan: Partial<Plan> = {
      companyName,
      buildingQuantity,
      stairwellQuantity,
      floorQuantity,
      apartmentQuantity
    }

    dispatch(updatePlan(updatedPlan))
    props.props.nextStep()
  }

  return (
    <div>
      <h4 className='mb-4'>Taloyhtiön tiedot</h4>
      <div className='row mb-3'>
        <div className='col-12 col-md-8'>
          <label>Taloyhtiön nimi *</label>
          <input data-testid='company-name' type='text' className='form-control mb-3' name='' placeholder='' value={companyName} onChange={(e) => { set(setCompanyName, e.target.value, 'string') }} />
          <label>Rakennusten lukumäärä *</label>
          <Tooltip title='Montako rakennusta, joissa on asuntoja, taloyhtiössä on?' />
          <input data-testid='building-quantity' type='text' className='form-control mb-3' name='' placeholder='' value={buildingQuantity ?? ''} onChange={(e) => { set(setBuildingQuantity, e.target.value, 'number') }} />
          <label>Rappujen lukumäärä *</label>
          <Tooltip title='Montako rappua tai porrasta taloyhtiössä on?' />
          <input data-testid='stairwell-quantity' type='text' className='form-control mb-3' name='' placeholder='' value={stairwellQuantity ?? ''} onChange={(e) => { set(setStairwellQuantity, e.target.value, 'number') }} />
          <label>Kerrosten lukumäärä *</label>
          <Tooltip title='Jos määrä vaihtelee eri talojen välillä, anna tähän korkeimman talon kerrosluku.' />
          <input data-testid='floor-quantity' type='text' className='form-control mb-3' name='' placeholder='' value={floorQuantity ?? ''} onChange={(e) => { set(setFloorQuantity, e.target.value, 'number') }} />
          <label>Asuntojen lukumäärä *</label>
          <input data-testid='apartment-quantity' type='text' className='form-control mb-0' name='' placeholder='' value={apartmentQuantity ?? ''} onChange={(e) => { set(setApartmentQuantity, e.target.value, 'number') }} />
        </div>
        <div className='d-none d-md-block col-4 text-center'>
          <i className='fas fa-building fa-10x mr-4' style={{ color: '#d2ddec' }} />
        </div>
      </div>
      <button data-testid='block-details-prev' className='btn btn-default me-3' onClick={props.props.previousStep}>Edellinen</button>
      <button data-testid='block-details-next' className='btn btn-primary' disabled={!isValid} onClick={proceed}>Seuraava</button>
    </div>
  )
}

const BuildingDetailsTerraced = (props: any) => {
  const plan = useAppSelector<Plan>((state) => state.plan)
  const dispatch = useAppDispatch()
  const [companyName, setCompanyName] = useState<string>(plan.companyName)
  const [buildingQuantity, setBuildingQuantity] = useState<number | undefined>(plan.buildingQuantity)
  const [apartmentQuantity, setApartmentQuantity] = useState<number | undefined>(plan.apartmentQuantity)
  const [centralUnitPlacedInMiddle, setCentralUnitPlacedInMiddle] = useState<'' | YesNo>(plan.centralUnitPlacedInMiddle)

  const isValid = companyName !== '' &&
        (buildingQuantity ?? 0) !== 0 &&
        (apartmentQuantity ?? 0) !== 0 &&
        centralUnitPlacedInMiddle !== ''

  const proceed = async () => {
    const updatedPlan: Partial<Plan> = {
      companyName,
      buildingQuantity,
      apartmentQuantity,
      centralUnitPlacedInMiddle
    }

    dispatch(updatePlan(updatedPlan))
    props.props.nextStep()
  }

  return (
    <div>
      <h4 className='mb-4'>
        {plan.buildingType === BUILDING_TYPE_TERRACED ? 'Rivitalon tiedot' : null}
        {plan.buildingType === BUILDING_TYPE_DUPLEX ? 'Paritalon tiedot' : null}
        {plan.buildingType === BUILDING_TYPE_DETACHED ? 'Erillistalon tiedot' : null}
      </h4>
      <div className='row'>
        <div className='col-12 col-md-8'>
          <label>Taloyhtiön nimi *</label>
          <input data-testid='company-name' type='text' className='form-control mb-3' name='' placeholder='' value={companyName} onChange={(e) => { set(setCompanyName, e.target.value, 'string') }} />
          <label>Rakennusten lukumäärä *</label>
          <Tooltip title='Montako rakennusta, joissa on asuntoja, taloyhtiössä on?' />
          <input data-testid='building-quantity' type='text' className='form-control mb-3' name='' placeholder='' value={buildingQuantity ?? ''} onChange={(e) => { set(setBuildingQuantity, e.target.value, 'number') }} />
          <label>Asuntojen lukumäärä *</label>
          <input data-testid='apartment-quantity' type='text' className='form-control mb-3' name='' placeholder='' value={apartmentQuantity ?? ''} onChange={(e) => { set(setApartmentQuantity, e.target.value, 'number') }} />
          <label>Saadaanko keskusyksikkö sijoitettua talojen keskelle? *</label>
          <RadioButtons
            data-testid='central-unit-placed-in-middle'
            name='centralUnitPlacedInMiddle'
            value={centralUnitPlacedInMiddle}
            options={[
              { value: 'yes', display: 'Kyllä' },
              { value: 'no', display: 'Ei' }
            ]}
            onChange={setCentralUnitPlacedInMiddle}
          />
        </div>
        <div className='d-none d-md-block col-4 text-center'>
          {plan.buildingType === BUILDING_TYPE_TERRACED
            ? <i className='fas fa-city fa-10x mr-4' style={{ color: '#d2ddec' }} />
            : null}
          {plan.buildingType === BUILDING_TYPE_DUPLEX
            ? <i className='fas fa-home fa-10x mr-4' style={{ color: '#d2ddec' }} />
            : null}
          {plan.buildingType === BUILDING_TYPE_DETACHED
            ? <i className='fas fa-home fa-10x mr-4' style={{ color: '#d2ddec' }} />
            : null}
        </div>
      </div>
      <button
        data-testid='building-details-prev' className='btn btn-default me-3'
        onClick={props.props.previousStep}
      >Edellinen
      </button>
      <button
        data-testid='building-details-next' className='btn btn-primary' disabled={!isValid}
        onClick={proceed}
      >Seuraava
      </button>
    </div>
  )
}

function usePlanUpdateCallback (field: keyof Plan, dispatch: Dispatch<any>): (newValue: string) => void {
  return useCallback((newValue: string) => {
    const updatedPlan: Partial<Plan> = {
      [field]: newValue
    }

    dispatch(updatePlan(updatedPlan))
  }, [field, dispatch])
}

const MeterDetails = (props: any) => {
  const plan = useAppSelector<Plan>((state) => state.plan)
  const dispatch = useAppDispatch()
  const {
    waterMeterType,
    moreThanOneMeterPerApartment,
    meterSize,
    apartmentScreensEnabled,
    systemType,
    temperatureMetersEnabled,
    wirelessMode
  } = plan

  const onChangeWaterMeterType = usePlanUpdateCallback('waterMeterType', dispatch)
  const onChangeMoreThanOneMeterPerApartment = usePlanUpdateCallback('moreThanOneMeterPerApartment', dispatch)
  const onWirelessModeChange = usePlanUpdateCallback('wirelessMode', dispatch)
  const onChangeApartmentScreensEnabled = usePlanUpdateCallback('apartmentScreensEnabled', dispatch)
  const onChangeSystemType = usePlanUpdateCallback('systemType', dispatch)
  const onChangeTemperatureMetersEnabled = usePlanUpdateCallback('temperatureMetersEnabled', dispatch)

  function onChangeMeterSize (newValue: MeterSize): void {
    const updatedPlan: Partial<Plan> = {
      meterSize: newValue
    }

    if (newValue === 'dn20' && plan.wirelessMode === 'hydrolinkAir') {
      updatedPlan.wirelessMode = ''
    }

    dispatch(updatePlan(updatedPlan))
  }

  const isValid = () => {
    if (waterMeterType === '') { return false }
    if (moreThanOneMeterPerApartment === '') { return false }
    if (meterSize === '') { return false }
    if (apartmentScreensEnabled === '') { return false }
    if (systemType === '') { return false }
    if (systemType === 'wireless') {
      if (temperatureMetersEnabled === '') { return false }
      if (wirelessMode === '') { return false }
    }
    return true
  }
  const proceed = async () => {
    dispatch(updatePlan({
      waterMeterType,
      moreThanOneMeterPerApartment,
      meterSize,
      apartmentScreensEnabled,
      systemType,
      temperatureMetersEnabled,
      wirelessMode
    }))
    dispatch(resetReportState())
    /* eslint-disable @typescript-eslint/await-thenable */
    await dispatch(generateProductList())
    props.nextStep()
  }

  return (
    <div>
      <h4 className='mb-4'>Mittausjärjestelmän tiedot</h4>
      <div className='row'>
        <div className='col-12 col-md-8'>
          <label>Mittaukset *</label>
          <RadioButtons
            name='waterMeterType'
            value={waterMeterType}
            options={[
              { value: 'both', display: 'KV+LV' },
              { value: 'cold', display: 'KV' },
              { value: 'warm', display: 'LV' }
            ]}
            onChange={onChangeWaterMeterType}
          />

          <label>Mittariparien lukumäärä per huoneisto *</label>
          <Tooltip title={'Jos huoneistojen kaikki vesi kulkee yksien mittareiden kautta, anna tähän arvoksi ' +
                        '1. Jos taas kylphuoneen ja keittiön vedet tulevat eri nousuista, anna arvoksi 2.'}
          />

          {/* <label>Onko huoneistoissa useampia mittarointeja kuin 1 / huoneisto? *</label> */}
          <RadioButtons
            name='moreThanOneMeterPerApartment'
            value={moreThanOneMeterPerApartment}
            options={[
              { value: 'no', display: '1' },
              { value: 'yes', display: '2' }
            ]}
            onChange={onChangeMoreThanOneMeterPerApartment}
          />

          <label>Mittarikoko *</label>
          <RadioButtons
            name='meterSize'
            value={meterSize}
            options={[
              { value: 'dn15', display: 'DN15' },
              { value: 'dn20', display: 'DN20' }
            ]}
            onChange={onChangeMeterSize}
          />

          <label>Huoneistonäytöt *</label>
          <RadioButtons
            name='apartmentScreensEnabled'
            value={apartmentScreensEnabled}
            options={[
              { value: 'yes', display: 'Kyllä' },
              { value: 'no', display: 'Ei' }
            ]}
            onChange={onChangeApartmentScreensEnabled}
          />

          <label>Langallinen vai langaton järjestelmä? *</label>
          <RadioButtons
            name='systemType'
            value={systemType}
            options={[
              { value: 'wired', display: 'Langallinen' },
              { value: 'wireless', display: 'Langaton' }
            ]}
            onChange={onChangeSystemType}
          />

          {
                        systemType === 'wireless'
                          ? <div className='card p-3 mb-3'>
                            <h6 className='mb-2'>Langattoman vedenmittausjärjestelmän tiedot</h6>
                            <label>Kumpi vedenmittausjärjestelmä? *</label>
                            <RadioButtons
                              name='wirelessMode'
                              value={wirelessMode}
                              options={[
                                { value: 'hydrolinkWireless', display: 'Hydrolink Wireless' },
                                {
                                  value: 'hydrolinkAir',
                                  display: 'Hydrolink Air',
                                  disabled: plan.meterSize === 'dn20',
                                  disabledReasonTooltip:
                                            'Hydrolink Air valittavissa vain DN15 tyyppiselle mittarikoolle'
                                }
                              ]}
                              onChange={onWirelessModeChange}
                            />
                            <label>Lämpötilan- ja kosteudenmittaus *</label>
                            <RadioButtons
                              name='temperatureMetersEnabled'
                              value={temperatureMetersEnabled}
                              options={[
                                { value: 'yes', display: 'Kyllä' },
                                { value: 'no', display: 'Ei' }
                              ]}
                              onChange={onChangeTemperatureMetersEnabled}
                            />
                            </div>
                          : null
                    }

        </div>
        <div className='d-none d-md-block col-4 text-center'>
          <i className='fas fa-tachometer-alt fa-10x mr-4' style={{ color: '#d2ddec' }} />
        </div>
      </div>
      <button className='btn btn-default me-3' onClick={props.previousStep}>Edellinen</button>
      <button
        data-testid='meter-details-next' className='btn btn-primary' disabled={!isValid()}
        onClick={proceed}
      >Seuraava
      </button>
    </div>

  )
}

function ShoppingEntry (props: { entry: ProductsListEntry }): JSX.Element {
  return (
    <tr>
      <td>
        {props.entry.description}
      </td>
      <td>
        <small className='text-muted'>x{props.entry.quantity}</small>
      </td>
    </tr>
  )
}

function ShoppingSum (props: { totalCost: number }): JSX.Element {
  const alv0 = props.totalCost / 100
  const alv255 = (props.totalCost * 1.255) / 100

  return (
    <tfoot>
      <tr>
        <td><strong>Yhteensä (alv 0%)</strong></td>
        <td><strong>{alv0.toLocaleString('fi', { style: 'currency', currency: 'EUR' })}</strong></td>
      </tr>
      <tr>
        <td><strong>Yhteensä (alv 25,5%)</strong></td>
        <td><strong>{alv255.toLocaleString('fi', { style: 'currency', currency: 'EUR' })}</strong></td>
      </tr>
    </tfoot>
  )
}

function ReportForm ({ onCreateClick }: { onCreateClick: (contact: boolean, email: string, message: string) => void }): JSX.Element {
  const initialEmail = useAppSelector<string>(state => state.user.claims?.email ?? '')
  const initialMessage = useAppSelector<string>(state => state.user.claims?.message ?? '')
  const submittingToAddress = useAppSelector<string>(state => state.report.submittingToAddress)
  const submittedToAddress = useAppSelector<string>(state => state.report.submittedToAddress)

  const [contactInput, contact] = useMdbCheckInput(
    false,
    'Haluan, että minuun otetaan yhteyttä',
    { id: 'contactCheck', testId: 'contact-checkbox' })

  const [emailInput, email] = useEmailInput(
    initialEmail,
    'form-control mb-3',
    'Sähköpostiosoite')
  const [messageInput, message] = useMessageInput(
    initialMessage,
    'form-control mb-3',
    'Tähän voit halutessasi antaa lisätietoja')

  const submitting = email !== '' && submittingToAddress === email
  const submitted = submittedToAddress === email

  function onClick () {
    if (!submitting) {
      onCreateClick(contact, email, message)
    }
  }

  const classes = {
    btn: true,
    'btn-primary': !submitted,
    'btn-light': submitted
  }

  return (
    <div>
      {contactInput}
      {emailInput}
      {messageInput}
      <button data-testid='create-report' className={classNames(classes)} onClick={onClick} disabled={submitting}>
        {submitting
          ? (
            <>
              <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true' />
              <span style={{ verticalAlign: 'top' }}>Lähetetään...</span>
            </>
            )
          : null}
        {!submitting && submitted
          ? (
            <i className='fas fa-check text-success fa-lg me-2' />
            )
          : null}
        {!submitting ? 'Lähetä laskelma' : null}
      </button>
      <p data-testid='link-to-planner' className='mt-5'>Suunnittelun tuki:
        <a href='mailto:suunnittelija@koka.fi'>suunnittelija@koka.fi</a> / 010 286 2012
      </p>
    </div>
  )
}

const SummaryRow = ({ summaryKeys }: { summaryKeys: Array<keyof Plan> }): JSX.Element => {
  const plan = useAppSelector<Plan>((state) => state.plan)
  return (
    <tbody style={{ fontWeight: 'normal' }}>
      {
            summaryKeys.map((k) => (
              <tr key={k}>
                <td>
                  {descriptions[k].description}
                </td>
                <td>
                  {descriptions[k][plan[k] ?? '']?.description ?? plan[k] ?? ''}
                </td>
              </tr>
            ))
        }
    </tbody>
  )
}

const Summary = (props: any) => {
  const plan = useAppSelector<Plan>((state) => state.plan)
  const productsList = useAppSelector<ProductsList>((state) => state.products.list)
  const productsError = useAppSelector<string>((state) => state.products.error)
  const dispatch = useAppDispatch()

  function onCreateReport (contact: boolean, email: string, message: string) {
    dispatch(createReport({ contact, email, plan, message }))
  }

  const objKeys = Object.keys(plan) as Array<keyof Plan>
  const keys: Array<keyof Plan> = objKeys.filter((k: keyof Plan) => (plan[k] !== undefined && plan[k] !== ''))
  const filteredKeys: Array<keyof Plan> = objKeys.filter((k: keyof Plan) => (k !== 'temperatureMetersEnabled' && k !== 'wirelessMode'))
  return (
    <div>
      <div className='row mb-3'>
        <div className='col-12 col-md-12'>
          <Drawing />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12 col-md-6'>
          <h4 data-testid='summary-heading' className='mb-4'>Yhteenveto</h4>
          <table data-testid='summary-table' className='table'>
            {(plan.systemType === 'wired')
              ? (
                <SummaryRow summaryKeys={filteredKeys} />
                )
              : (
                <SummaryRow summaryKeys={keys} />
                )}
          </table>
        </div>
        <div className='col-12 col-md-6'>
          <h4 className='mb-4'>Hinta-arvio</h4>
          {!productsError
            ? (
              <table data-testid='products-table' className='table mb-4'>
                <tbody style={{ fontWeight: 'normal' }}>
                  {productsList?.entries.map(entry => (
                    <ShoppingEntry key={entry.description} entry={entry} />
                  ))}
                </tbody>
                <ShoppingSum totalCost={productsList?.totalCost ?? 0} />
              </table>
              )
            : (
              <p className='mb-4'>
                <strong className='text-danger'>
                  {productsError}
                </strong>
              </p>
              )}
          {(plan.systemType === 'wired' || plan.wirelessMode === 'hydrolinkWireless')
            ? <div className='mb-4'>
              <p>
                HYDROLINK Online -palvelumme on täysin maksuton.
              </p>
              <p>
                HYDROLINK Online Premium -palvelun vuosilisenssi taloyhtiölle on 390€ (alv 0%). Ei erikseen mittari- tai asuntokohtaisia maksuja. Premium-palvelussa mm. vuotovalvonta sekä mobiilisovellukset asukkaille oman huoneiston vedenkulutuksen seurantaan. Tarjoamme ensimmäisen vuoden kokeilujaksona veloituksetta.
              </p>
              <p>
                HYDROLINK Online API -paketin vuosilisenssi taloyhtiölle on 390€ (alv 0%).  API-paketin avulla saadaan siirrettyä Hydrolink-järjestelmän lukema- sekä lämpötilatiedot automatisoidusti kolmannen osapuolen ohjelmistoihin.
              </p>
              </div>
            : null}
          {((plan.buildingQuantity ?? 0) > 1 && plan.systemType === 'wireless')
            ? <div className='alert alert-warning d-flex justify-content-between' role='alert'>
              Huomioithan, että useamman rakennuksen langattomat järjestelmät ovat hyvinkin erilaisia keskenään, eikä suunnittelukoneella pystytä luotettavasti suunnittelemaan näitä.
              Ole siis yhteydessä myyntiin saadaksesi tarkemman hinnan!
            </div>
            : null}

          <ReportForm onCreateClick={onCreateReport} />
        </div>
      </div>
      <button data-testid='summary-prev' className='btn btn-default me-3' onClick={props.previousStep}>Edellinen</button>
    </div>
  )
}
